import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'kt-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})

export class DataTableComponent implements OnInit {

  ngOnInit() { }
}
