// USA
export const locale = {
	lang: "en",
	data: {
		TRANSLATOR: {
			SELECT: "Select your language",
		},
		MENU: {
			NEW: "جديد",
			DASHBOARD: "الصفحة الرئيسية",
			GOVERNORATES: "المحافظات",
			CITIES: "المدن",
			BRANDS: "الماركات",
			CARMODELS: "موديلات السيارات",
			TRANSMISSION: "ناقل الحركة",
			BODY: "النمط",
			COLORS: "الالوان",
			PULLTYPES: "نوع الجر",
			STEERINGWHEELS: "عجلات القيادة",
			ATTRIBUTES: "المواصفات",
			CARS: "جميع السيارات",
			NEWCARS: "السيارات الجديدة",
			USEDCARS: "السيارات المستعملة",
			CATALOGUES: "الكاتولجات",
			BRANCHES: "الفروع",
			USERS: "المستخدمون",
			SLIDERS: "سلايدر",
			NEWS: "الاخبار",
			OFFERS: "العروض",
			CATEGORIES: "الفئات",
			GROUPS: "المجموعات",
			INSURANCE_RENEWAL: "طلبات تأمين السيارات",
			SETTINGS: "الاعدادات",
			BUDGETS: "الميزانيات",
			FAVOURITES: "المفضلات",
			MESSAGES: "الرسائل",
			JOBTITLE: "المسمي الوظيفي",
			FILTER: "قائمة البحث",
			ATTORNEY: "مندوب الترخيص",
			BANKACCOUNTS: "الحسابات البنكية",
			FAQS: "الأسئلة والأجوبة",
			ORDERS: "طلبات السيارات",
			ADVERTISING: "اعلان السيارات",
			ROLES: "الأدوار والأذونات",
			MANAGERS: "المديرين"
		},
		AUTH: {
			GENERAL: {
				OR: "او",
				SUBMIT_BUTTON: "تسجيل",
				NO_ACCOUNT: "Don't have an account?",
				SIGNUP_BUTTON: "Sign Up",
				FORGOT_BUTTON: "Forgot Password",
				BACK_BUTTON: "Back",
				PRIVACY: "Privacy",
				LEGAL: "Legal",
				CONTACT: "Contact",
			},
			LOGIN: {
				TITLE: "تسجيل الدخول",
				BUTTON: "تسجيل الدخول",
			},
			INPUT: {
				EMAIL: "البريد الالكتروني",
				FULLNAME: "الاسم",
				PASSWORD: "كلمة المرور",
				CONFIRM_PASSWORD: "تاكيد كلمة المرور",
				USERNAME: "اسم المستخدم",
			},
			VALIDATION: {
				INVALID_LOGIN: "The login detail is incorrect",
				MIN_LENGTH_FIELD: "Minimum field length:",
				MAX_LENGTH_FIELD: "Maximum field length:",
				INVALID_FIELD: "Field is not valid",
			},
		},
		COMPONENTS: {
			noDate: "لا يوجد بيانات متاحة الآن",
			ID: "م",
			NameEn: "الأسم انجليزي",
			NameAr: "الأسم عربي",
			updated: "آخر تعديل",
			actions: "الأحداث",
			logo: "الصورة",
			code: "الرمز",
			value: "القيمة",
			priceFrom: "السعر الادني",
			priceTo: "السعر الاقصي",
			used: "مستعملة",
			unUsed: "جديده",
			status: "الحالة",
			url: "الرابط",
			boolean: "نعم او لا",
			text: "نص",
			values: "قيم",
			name: "الاسم",
			email: "البريد الاكتروني",
			phone: "رقم المحمول",
			titleEn: "العنوان انجليزي",
			titleAr: "العنوان عربي",
			brand: "الماركة",
			price: "السعر",
			itemType: "النوع",
			chassie_no: "رقم الشاسية",
			monthly_payment: "الدفع الشهري",
			discount: "الخصم",
			imageBack: "الصوره الامامية",
			imageFront: "الصوره الخلفية",
			favouriteType: "نوع المفضلات",
			fromUser: "اسم الراسل",
			toUser: "اسم المرسل اليه",
			fromEmailUser: "بريد الكتروني الراسل",
			toEmailUser: "بريد الكتروني المرسل اليه",
			bank_benfit_rate: "معدل فائدة البنك",
			max_installment_years: "الحد الأقصى للسنوات",
			questionEn: "السؤال انجليزي",
			power_of_attorney_images: "صورة التوكيل",
			EGP: "جنية",
			adsTitle: "اسم الأعلان",
			model: "الموديل",
			adsCount: "عدد الاعلانات",
			abaza_used_cars_count: "عدد سيارات اباظه المستعملة",
			cars_no_catalogue_count: "عدد السيارات ليس لها كاتالوج",
			cars_no_video_count: "عدد السيارات ليس لها فيديو",
			insurance_renewals_confirmed_count: "عدد طلبات التامين الموكدة",
			insurance_renewals_paid_count: "عدد طلبات التامين المدفوعة",
			insurance_renewals_pending_count: "عدد طلبات التامين المنتظره",
			insurance_renewals_rejected_count: "عدد طلبات التامين المرفوضة",
			most_added_used_car_brand: "اكثر الماركات المضافة للسيارات المستعملة",
			most_added_used_car_model: "اكثر الموديلات المضافة للسيارات المستعملة",
			orders_confirmed_count: "عدد الطلبات المؤكدة",
			orders_paid_count: "عدد الطلبات المدفوعة",
			orders_pending_count: "عدد الطلبات المنتظرة",
			orders_rejected_count: "عدد الطلبات المرفوضة",
			used_cars_added_this_month_count: "عدد السيارات المستعملة المضافة هذا الشهر",
			used_cars_added_this_week_count: "عدد السيارات المستعملة المضافة هذا الاسبوع",
			used_cars_added_today_count: "عدد السيارات المستعملة المضافة اليوم",
			users_added_this_month_count: "عدد العملاء هذا الشهر",
			users_added_this_week_count: "عدد العملاء هذا الاسبوع",
			users_added_today_count: "عدد العملاء اليوم",
			users_used_cars_count: "عدد السيارات المستعمله للعملاء",
			ACTIONS: {
				edit: "تعديل البيانات",
				delete: "حذف",
				activate: "تفعيل",
				deactivate: "تعطيل",
				unBlock: "الغاء الحظر",
				block: "حظر",
				view: "عرض التفاصيل",
			},
		},
		GENERAL: {
			HEADER: {
				addGovernorate: "إضافة محافظة",
				addCity: "إضافة مدينة",
				addBrand: "إضافة ماركة",
				addCarModel: "إضافة موديل",
				addBody: "إضافة نمط",
				addColor: "إضافة لون",
				addPullType: "إضافة نوع جر",
				addTransmission: "إضافة ناقل الحركة",
				addSteeringWheel: "إضافة عجلة قيادة",
				addAttribute: "إضافة وصف",
				addCar: "إضافة سيارة",
				addCatalogues: "إضافة كتالوج",
				addUser: "إضافة مستخدم",
				addBranches: "إضافة فرع ",
				addNews: "إضافة خبر",
				addSlider: "إضافة سلايدر ",
				addCategory: "إضافة فئة",
				addGroup: "إضافة مجموعة",
				addOffers: "إضافة عرض",
				addFaqs: "إضافة سؤال وجواب",
				addSettings: "إضافة",
				addInsurance: "طلب تجديد التامين",
				addBudget: "إضافة ميزانية",
				addJobTitle: "إضافة مسمي وظيفي",
				addAttorney: "إضافة مندوب",
				addBankAccount: "إضافة حساب بنكي",
				addOrder: "إضافة طلب",
				addAdvertising: "إضافة إعلان",
				addRole: "إضافة دور",
				save: "حفظ",
				back: "عودة",
				reset: "إعادة",
				search: "بحث",
				dateFrom: "التاريخ من",
				dateTo: "التاريخ الي",
				searchByName: "بحث عن طريق الأسم",
				searchByFirstName: "بحث عن طريق الاسم الاول",
				searchByLastName: "بحث عن طريق اللقب",
				searchByEmail: "بحث عن طريق البريد الاكتروني",
				byUsers: "بواسطة العملاء",
				byAbaza: "بواسطة اباظة",
				TOOLTIP: {
					addNewGovernorate: "إضافة محافظة جديدة",
					addNewCity: "إضافة مدينة جديدة",
					addNewBrand: "إضافة ماركة جديدة",
					addNewCarModel: " إضافة موديل جديد",
					addNewBody: "إضافة النمط جديد",
					addNewColor: "إضافة لون جديد",
					addNewPullType: "إضافة نوع جر جديد",
					addNewSteeringWheel: "إضافة عجلة قيادة جديدة",
					addNewTransmission: "إضافة ناقل الحركة جديد",
					addNewAttribute: "إضافة خاصية جديدة",
					addNewCar: "إضافة سيارة جديدة",
					addNewCatalogues: "إضافة كتالوج جديد",
					addNewBranches: "إضافة فرع جديد",
					addNewNews: "إضافة خبر جديد",
					addNewUser: "إضافة مستخدم جديد",
					addNewSlider: "إضافة سلايدر جديد",
					addNewCategory: "إضافة فئة جديد",
					addNewGroup: "إضافة مجموعة جديدة",
					addNewInsurance: "طلب تجديد التامين",
					addNewSettings: "إضافة",
					addNewBudget: "إضافة ميزانية جديدة",
					addNewOffers: "إضافة عرض جديد",
					addNewJobTitle: "إضافة مسمي وظيفي جديد",
					addNewAttorney: "اضافه محامي جديد",
					addNewBankAccount: "إضافة حساب بنكي جديد",
					addNewFaqs: "إضافة سؤال وجواب جديد",
					addNewOrder: "إضافة طلب جديد",
					addNewAdvertising: "إضافة إعلان جديد",
					addNewRole: "إضافة دور جديد",
					// back to list pages
					backToTheGovernoratesList: "العودة الي قائمة المحافظات",
					backToTheCitiesList: "العودة الي قائمة المدن",
					backToTheBrandsList: "العوده الي قائمة الماركات",
					backToTheCarModelsList: "العودة الي قائمة الموديلات",
					backToTheColorsList: "العودة الي قائمة الالوان",
					backToThePullTypesList: "العودة الي قائمه نوع الجر",
					backToTheTransmissionList: "العودة الي قائمة ناقل الحركة",
					backToTheBodyList: "العودة الي قائمة النمط",
					backToTheSteeringWheelsList: "العودة الي قائمة عجلات القيادة",
					backToTheAttributesList: "العودة الي قائمة الخصائص",
					backToTheCarsList: "العودة الي قائمة السيارات",
					backToTheCataloguesList: "العودة الي قائمة الكاتالوجات",
					backToTheBrancheList: "العودة الي قائمة الفروع",
					backToTheUserList: "العودة الي قائمة المستخدمين",
					backToTheSlidersList: "العودة الي قائمة السلايدر",
					backToTheCategoriesList: "العودة الي قائمة الفئات",
					backToTheGroupsList: "العودة الي قائمة المجموعات",
					backToTheInsurancesList: "العودة الي التامينات",
					backToTheSettingList: "العودة الي قائمة الاعدادات",
					backToTheBudgetList: "العودة الي قائمة الميزانيات",
					backToTheOfferList: "العودة الي قائمة العروض",
					backToTheFavouritesList: "العوده الي قائمة المفضلات",
					backToTheMessagesList: "العودة الي قائمة الرسائل",
					backToTheFilterCriteriaList: "العودة الي قائمة البحث",
					backToTheJobTitleList: "العودة الي قائمة المسميات الوظيفية",
					backToAttorneyList: "العودة الي قائمة مندوبين الترخيص",
					backToTheBankAccountsList: "العودة الي قائمة الحسابات البنكية",
					backToTheFaqsList: "العودة الي قائمة الاسئله والاجوبة",
					backToTheOrdersList: "العوده الي قائمة الطلبات",
					backToTheAdvertisingList: "العوده الي قائمة الاعلانات",
					backToTheRolesList: "العودة الي قائمة الأدوار"
				},
			},
		},
		EDIT: {
			name: "الاسم",
			prices: "الاسعار",
			details: "التفاصيل",
			optional: "(اختياري)",
			selectDetails: "اختر من القوائم",
			values: "الصفات",
			chooseDetails: "اختر من التفاصيل",
			attributes: "المواصفات",
			carStatus: "حالة السيارة",
			bankAccounts: "الحسابات البنكية",
		},
		INPUT: {
			carDescriptionEn: "وصف السيارة انجليزي",
			carDescriptionAr: "وصف السيارة عربي",
			nameGovernorateEn: "اسم المحافظة إنجليزي",
			nameGovernorateAr: "اسم المحافظة عربي",
			nameBrandAr: "اسم الماركة عربي",
			nameBrandEn: "اسم الماركة إنجليزي",
			brand: "الماركة",
			nameModelEn: "اسم الموديل انجليزي",
			nameModelAr: "اسم الموديل عربي",
			nameBodyEn: "اسم النمط انجليزي",
			nameBodyAr: "اسم النمط عربي",
			code: "الرمز",
			nameColorEn: "اسم اللون انجليزي",
			nameColorAr: "اسم اللون عربي",
			namePullTypeEn: "اسم نوع الجر انجليزي",
			namePullTypeAr: "اسم نوع الجر عربي",
			nameSteeringWheelsEn: "اسم عجلة القيادة انجليزي",
			nameSteeringWheelsAr: "اسم عجلة القيادة عربي",
			nameTransmissionEn: "اسم ناقل الحركة انجليزي",
			nameTransmissionAr: "اسم ناقل الحركة عربي",
			value: "القيمة",
			nameCardEn: "اسم السيارة انجليزي",
			nameCardAr: "اسم السيارة عربي",
			priceFrom: "السعر الادني",
			priceTo: "السعر الاقصي",
			motorCC: "موتور سي سي",
			motorPowerByHorse: "قوة المحرك بالحصان",
			numberOfEngineGears: "عدد نقلات المحرك",
			numberOfCasting: "عدد السماعت",
			numberOfDoors: "عدد الابواب",
			numberOfSeats: "عدد المقاعد",
			fuelTankCapacity: "سعة خزان الوقود",
			dimensions: "الأبعاد",
			heightFromGround: "ارتفاع من الأرض",
			numOfCylender: "عدد الأسطوانات",
			acceleration: "التسارع",
			maxTorque: "العزم الاقصى",
			averageFuelConsumbtion: "متوسط استهلاك الوقود",
			bodyType: "نوع الهيكل",
			transmissionType: "نوع ناقل الحركة",
			pullType: "نوع الجر",
			steeringWheelType: "نوع عجلات القيادة",
			catalogue: "كاتالوج",
			color: "الالوان",
			model: "الموديل",
			URL: "الرابط",
			videoUrl: "رابط الفيديو",
			nameCatalogueEn: "اسم الكاتالوج انجليزي",
			nameCatalogueAr: "اسم الكاتالوج عربي",
			nameAttributeEn: "اسم الخاصية انجليزي",
			nameAttributeAr: "اسم الخاصية عربي",
			valueName: "اسم الخاصية",
			type: "النوع",
			textType: "اكتب تفاصيل الخاصية",
			name: "الاسم",
			status: "حالة السيارة",
			nameBrancheEn: "اسم الفرع انجليزي",
			nameBrancheAr: "اسم الفرع عربي",
			addressBrancheEn: "عنوان الفرع انجليزي",
			addressBrancheAr: "عنوان الفرع عربي",
			brancheNumber: "رقم هاتف الفرع",
			latitude: "خط العرض",
			longitude: "خط الطول",
			nameCityEn: "اسم المدينة انجليزي",
			nameCityAr: "اسم المدينة عربي",
			governorate: "المحافظة",
			firstName: "الاسم",
			lastName: "اللقب",
			email: "البريد الالكتروني",
			phoneNumber: "رقم الهاتف",
			birthDate: "تاريخ الميلاد",
			gender: "الجنس",
			city: "المدينة",
			address: "العنوان",
			password: "كلمة المرور",
			nameSliderEn: "اسم السلايدر انجليزي",
			nameSliderAr: "اسم السلايدر عربي",
			confirmPassword: "تاكيد كلمة المرور",
			car: "السيارة",
			news: "الاخبار",
			offers: "العروض",
			nameCategoryEn: "اسم الفئة انجليزي",
			nameCategoryAr: "اسم الفئة عربي",
			nameGroupEn: "اسم المجموعة انجليزي",
			nameGroupAr: "اسم المجموعة عربي",
			group: "المجموعة",
			titleNewsEn: "عنوان الخبر انجليزي",
			titleNewsAr: "عنوان الخبر عربي",
			date: "التاريخ",
			user: "المستخدم",
			descriptionNewsEn: "تفاصيل الخبر انجليزي",
			descriptionNewsAr: "تفاصيل الخبر عربي",
			price: "السعر",
			chassie_no: "رقم الشاسية",
			branch: "الفرع",
			notes: "الملاحظات",
			nameOfferAr: "اسم العرض عربي",
			nameOfferEn: "اسم العرض انجليزي",
			start_date: "تاريخ البداية",
			end_date: "تاريخ الانتهاء",
			discount: "الخصم",
			monthly_payment: "الدفع الشهري",
			advance: "مقدما",
			average_fuel_consumbtion: "متوسط استهلاك الوقود",
			manufacture_year: "سنة التصنيع",
			new_year_price: "سعر العام الجديد",
			calculate_it_your_self_price: "احسبها بنفسك",
			power: "بور",
			electric_injection: "حقن الكترونى",
			category: "الفئة",
			tags: "العلامات (من فضلك اضغط enter بعد كل كلمة)",
			carComparevalue: "اقصي عدد مقارنة للسيارات",
			userName: "اسم المستخدم",
			userEmail: "البريد الاليكتروني للمستخدم",
			userPhone: "رقم هاتف المستخدم",
			itemType: "نوع المفضل",
			itemName: "اسم المفضل",
			fromUserName: "اسم الراسل",
			fromEmail: "بريد اللكتروني الراسل",
			fromPhone: "هاتف الراسل",
			toUserName: "اسم المرسل اليه",
			toEmail: "بريد اللكتروني المرسل اليه",
			toPhone: "هاتف المرسل اليه",
			messageBody: "محتوي الرسالة",
			nameFilterEn: "اسم البحث انجليزي",
			nameFilterAr: "اسم البحث عربي",
			nameJobTitleEn: "اسم المسمي الوظيفي انجليزي",
			nameJobTitleAr: "اسم المسمي الوظيفي عربي",
			bank_benfit_rate: "معدل فائدة البنك",
			max_installment_years: "الحد الأقصى للسنوات",
			statusInsurance: "حالة طلب التامين",
			discountType: "نوع الخصم",
			nameAttorneyEn: "اسم مندوب الترخيص انجليزي",
			nameAttorneyAr: "اسم مندوب الترخيص عربي",
			accountNumber: "رقم الحساب",
			nameBankAccountEn: "اسم الحساب انجليزي",
			nameBankAccountAr: "اسم الحساب عربي",
			questionEn: "السؤال انجليزي",
			questionAr: "السؤال عربي",
			answerEn: "الإجابة انجليزي",
			answerAr: "الإجابة عربي",
			deliveryPlace: "مكان التوصيل",
			orderStatus: "حالة الطلب",
			paymentTypes: "طرق الدفع",
			advancePayment: "دفع مقدمة",
			attorneyPersons: "مندوبين الترخيص",
			statusNote: "ملاحظات الحاله",
			days: "الايام",
			times: "الوقت",
			bankAccounts: "الحساب البنكي",
			catalogueUrl: "رابط الكاتالوج",
			descriptionOfferEn: "وصف العرض انجليزي",
			descriptionOfferAr: "وصف العرض عربي",
			addressAttorneyEn: "عنوان المندوب إنجليزي",
			addressAttorneyAr: "عنوان المندوب عربي",
			nationalIdNoAttorney: "الرقم القومي للمندوب",
			nationalIdImgAttorney: "صورة الرقم القومي للمندوب",
			attorney_formula: "صيغة توكيل المندوب",
			min_advance_payment_rate: 'الحد الادنى لمقدم الدفع',
			interestRate: "نسبة سعر الفائدة",
			administrativeExpensesRate: "نسبة المصاريف الإدارية",
			insuranceAmountRate: "نسبة مبلغ التأمين",
			stock: "عدد السيارات المتاحة (اونلاين)",
			roles: "الصلاحيات",
			valueEn: "القيمة انجليزي",
			valueAr: "القيمة عربي",
			VALIDATION: {
				passwordDoesnotMatch: "كلمة المرور غير متطابقة",
			},
		},
		ROLE: {
			report: "التقارير",
			slider: "سلايدر",
			budget: "الميزانية",
			faq: "الأسئله",
			carAd: "اعلانات السيارات",
			payment: "الدفع",
			insuranceRenewal: "طلبات تأمين السيارات",
			filterCriteria: "قائمة البحث",
			news: "الاخبار",
			jobTitle: "المسمي الوظيفي",
			offer: "العروض",
			order: "الطلبات",
			attorneyPerson: "مندوبين الترخيص",
			bankAccount: "الحسابات البنكية",
			favourite: "المفضلات",
			message: "الرسائل",
			branch: "الفروع",
			role: "الصلاحيات",
			conversation: "المحادثات",
			permission: "الاوامر",
			car: "السيارات",
			oauthClient: "المديرين",
			pushNotificationDevice: "ارسال الاشعارات",
			bodyType: "نوع الهيكل",
			transmissionType: "نوع ناقل الحركة",
			color: "اللاوان",
			category: "الفئات",
			steeringWheelType: "نوع عجلات القيادة",
			user: "العملاء",
			pullType: "نوع الجر",
			brand: "الماركات",
			carModel: "موديلات السيارات",
			catalogue: "الكاتالوج",
			setting: "الاعدادات",
			attributeGroup: "الخصائص",
			attribute: "الخصائص",
			notification: "الاشغارات",
			city: "المدن",
			governorate: "المحافظات",
			general: "العام",
			ITEMS: {
				index: "عرض الكل",
				show: "مشاهدة",
				store: "اضافة",
				update: "تعديل",
				destroy: "مسح",
				deleted: "مسح",
				restore: "اعادة",
				adminDashboard: "الصفحه الرئيسية",
				dashboard: "الصفحه الرئيسية",
				assignPermissions: "إضافة صلاحية",
				assignRoles: "إضافة صلاحيات",
				unread: "Un Read",
				markAsRead: "Mark As Read",
				markAllAsRead: "Mark All As Read",
				sendNotification: "Send Notification"
			}
		}
	},
};
