import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import {
  HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpHeaderResponse,
  HttpProgressEvent, HttpResponse, HttpUserEvent, HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../../../views/pages/auth/services/auth.service';


@Injectable({
  providedIn: 'root'
})
export class JWTInterceptorService implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private router: Router,
  ) { }

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({ setHeaders: { 'Authorization': `Bearer ${token}` } });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent |
    HttpResponse<any> | HttpUserEvent<any>> {
    const authService = this.injector.get(AuthService);
    if (authService.getToken()) {
      return next.handle(this.addToken(req, authService.getToken())).pipe(
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            switch ((error as HttpErrorResponse).status) {
              case 400:
                return this.handle400Error(req, error);
              case 401:
                return this.handle401Error(req, next);
              default:
                return observableThrowError(error);
            }
          }
          this.logoutUser();
          return observableThrowError(error);
        })).pipe(
          finalize(() => { })
        );
    }
    return next.handle(req).pipe(
      finalize(() => { })
    );
  }

  handle400Error(req, error) {
    return observableThrowError(error);
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    // handle refresh token code here
    const authService = this.injector.get(AuthService);
    return authService.refreshToken().pipe(
      switchMap((res: any) => {
        if (res) {
          authService.setToken(res.data.status);
          return next.handle(this.addToken(req, authService.getToken()));
        }
        return this.logoutUser();
      }),
      catchError(error => {
        // If there is an exception calling 'refreshToken', bad news so logout.
        return this.logoutUser();
      })
    )
  }
  logoutUser() {
    // Route to the login page
    const authService = this.injector.get(AuthService);
    authService.clearCredentials();
    this.router.navigate([`/login`]);
    return observableThrowError('');
  }
}
