import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../../../../models/user.model';
import { environment } from '../../../../../environments/environment';
import { LocalStorageService } from '../../../../core/services/storage/local-storage.service';

export const API_USERS_URL = environment.serverUrl + 'users/account/';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) { }

  // Authentication/Authorization
  login(email: string, password: string): Observable<User> {
    return this.http.post<User>(`${API_USERS_URL + 'login'}`, { email, password });
  }

  account(): Observable<any> {
    return this.http.get<any>(`${API_USERS_URL + 'my'}`);
  }

  update(user: User): Observable<any> {
    return this.http.put<User>(`${API_USERS_URL + 'editProfile'}`, user);
  }

  paginate(pageSize = '10', pageNumber = '1', state: string = 'active'): Observable<User[]> {
    return this.http.get<User[]>(`${API_USERS_URL + 'users'}`, {
      params: {
        pageNumber,
        pageSize,
        state
      }
    });
  }

  logout(): Observable<any> {
    return this.http.post(`${API_USERS_URL + 'logout'}`, {});
  }

  changePassword(oldPass: string, newPass: string): Observable<any> {
    return this.http.put(
      `${API_USERS_URL + 'changePassword'}`,
      {
        oldPass,
        newPass
      },
    );
  }

  refreshToken() {
    return this.http.post(`${API_USERS_URL + 'refresh/token'}`, { refresh_token: this.getRefreshToken() });
  }

  getLoggedin(): User {
    const user: User = this.localStorageService.getData('loggedUser');
    return user;
  }

  isLoggedin(): boolean {
    const user: User = this.getLoggedin();
    if (user) {
      return true;
    }
    return false;
  }

  setCredentials(response: any) {
    const user: User = response;
    this.localStorageService.setData('loggedUser', user);
  }

  setPermissions(res) {
    this.localStorageService.setData('permissions', res);
  }

  getPermissions() {
    return this.localStorageService.getData('permissions');
  }

  setToken(response: any) {
    this.localStorageService.setData(`token`, `${response['access_token']}`);
    this.localStorageService.setData(`refresh_token`, `${response['refresh_token']}`);
  }

  getToken(): string {
    return this.localStorageService.getData('token');
  }

  getRefreshToken() {
    return this.localStorageService.getData('refresh_token');
  }

  clearCredentials() {
    this.localStorageService.clearAll();
  }
}
