import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../storage/local-storage.service';
import * as moment from 'moment';
import { formatDate } from '@angular/common';
@Injectable({
  providedIn: 'root'
})

export class HelperService {
  // default lang
  public lang = 'en';
  public routePath: string;
  public sidenav: string;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private localStorageService: LocalStorageService,
    ) {}

  public langDetect() {
    const lang = this.localStorageService.getData('lang');
    if (!lang) {
      this.localStorageService.setData('lang', this.lang);
      this.translate.use(this.lang);
    } else {
      this.lang = lang;
      this.translate.use(lang);
    }
  }


  fromatDate(date, format: string) {
    if (date) {
      return formatDate(date, format, 'en-us');
    }
  }

  fromatDateRange(dateRange: any) {
    if (dateRange && dateRange.from && dateRange.to) {
      return {
        from: dateRange.from.format('YYYY-MM-DD'),
        to: dateRange.to.format('YYYY-MM-DD'),
      };
    }
  }
}
