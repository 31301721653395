import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../../services/storage/local-storage.service';
import { AuthService } from '../../../views/pages/auth/services/auth.service';
import { HelperService } from '../../services/helper/helper.service';
@Injectable({
  providedIn: 'root'
})

export class AccessGuardService implements CanActivate {
  group: string;
  constructor(
    private router: Router,
    private helper: HelperService,
    private localStorageService: LocalStorageService,
    private authService: AuthService
    ) {}
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const user = this.authService.getLoggedin();
    // Check that the user is logged in...
    let loggedin;
    if (this.authService.isLoggedin()) {
      loggedin = true;
    } else {
      this.router.navigate([`/login`]);
      loggedin = false;
    }
    return loggedin;
  }
}


