import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { User } from '../../../../../models/user.model';
import { AuthService } from '../../../../../views/pages/auth/services/auth.service';

@Component({
  selector: 'kt-user-profile',
  templateUrl: './user-profile.component.html',
})
export class UserProfileComponent implements OnInit {
  // Public properties
  public user: User;

  @Input() avatar = true;
  @Input() greeting = true;
  @Input() badge: boolean;
  @Input() icon: boolean;

  constructor(
    private auth: AuthService,
    private router: Router
  ) {
    this.user = this.auth.getLoggedin();
  }

  ngOnInit(): void {
  }

  logout() {
    this.auth.clearCredentials();
    this.router.navigate([`/login`]);
  }

  userProfile() {
    this.router.navigate([`/users/edit/${this.user.id}`]);
  }
}
