import { environment } from "../../../environments/environment";

export const PERMISSIONS = JSON.parse(localStorage.getItem(environment.appName + '-permissions')) || {};

export class MenuConfig {
  constructor() {
  }

  public defaults: any = {
    aside: {
      self: {},
      items: [
        {
          title: 'الصفحة الرئيسية',
          root: true,
          translate: 'MENU.DASHBOARD',
          icon: 'flaticon2-architecture-and-city',
          page: '/dashboard',
          bullet: 'dot',
          permissions: PERMISSIONS['report'].includes('index') || PERMISSIONS['report'].includes('dashboard') || PERMISSIONS['general'].includes('adminDashboard')
        },
        {
          title: 'طلبات تأمين السيارات',
          root: true,
          translate: 'MENU.INSURANCE_RENEWAL',
          bullet: 'dot',
          icon: 'flaticon-presentation',
          page: '/insurance-renewal',
          permissions: PERMISSIONS['insuranceRenewal'].includes('index') || PERMISSIONS['insuranceRenewal'].includes('show')
        },
        {
          title: 'طلبات السيارات',
          root: true,
          translate: 'MENU.ORDERS',
          bullet: 'dot',
          icon: 'flaticon-graphic-2',
          page: '/orders',
          permissions: PERMISSIONS['order'].includes('index') || PERMISSIONS['order'].includes('show')
        },
        {
          title: 'المستخدمون',
          root: true,
          translate: 'MENU.USERS',
          icon: 'flaticon-users',
          page: '/users',
          bullet: 'dot',
          permissions: PERMISSIONS['user'].includes('index') || PERMISSIONS['user'].includes('show')
        },
        {
          title: 'السيارات الجديدة',
          translate: 'MENU.NEWCARS',
          root: true,
          bullet: 'dot',
          icon: 'fa fa-car',
          page: '/cars/new',
          permissions: PERMISSIONS['car'].includes('index') || PERMISSIONS['user'].includes('show')
        },
        {
          title: 'السيارات المستعملة',
          translate: 'MENU.USEDCARS',
          root: true,
          bullet: 'dot',
          icon: 'fa fa-car',
          page: '/cars/used',
          permissions: PERMISSIONS['car'].includes('index') || PERMISSIONS['car'].includes('show')
        },
        {
          title: 'تفاصيل السيارة',
          root: true,
          icon: 'flaticon-cogwheel-2',
          toggle: 'click',
          permissions: 
            PERMISSIONS['attribute'].includes('index') || PERMISSIONS['attribute'].includes('show') ||
            PERMISSIONS['attributeGroup'].includes('index') || PERMISSIONS['attributeGroup'].includes('show') ||
            PERMISSIONS['bodyType'].includes('index') || PERMISSIONS['bodyType'].includes('show') ||
            PERMISSIONS['color'].includes('index') || PERMISSIONS['color'].includes('show') ||
            PERMISSIONS['transmissionType'].includes('index') || PERMISSIONS['transmissionType'].includes('show') ||
            PERMISSIONS['pullType'].includes('index') || PERMISSIONS['pullType'].includes('show') ||
            PERMISSIONS['steeringWheelType'].includes('index') || PERMISSIONS['steeringWheelType'].includes('show'),
          submenu: [
            {
              title: 'المواصفات',
              translate: 'MENU.ATTRIBUTES',
              root: true,
              bullet: 'dot',
              icon: 'flaticon-edit-1',
              page: '/attributes',
              permissions: PERMISSIONS['attribute'].includes('index') || PERMISSIONS['attribute'].includes('show')
            },
            {
              title: 'المجموعات',
              translate: 'MENU.GROUPS',
              root: true,
              bullet: 'dot',
              icon: 'flaticon2-indent-dots',
              page: '/groups',
              permissions: PERMISSIONS['attributeGroup'].includes('index') || PERMISSIONS['attributeGroup'].includes('show')
            },
            {
              title: 'النمط',
              translate: 'MENU.BODY',
              root: true,
              bullet: 'dot',
              icon: 'flaticon-layers',
              page: '/body',
              permissions: PERMISSIONS['bodyType'].includes('index') || PERMISSIONS['bodyType'].includes('show')
            },
            {
              title: 'الالوان',
              translate: 'MENU.COLORS',
              root: true,
              bullet: 'dot',
              icon: 'flaticon-interface-8',
              page: '/colors',
              permissions: PERMISSIONS['color'].includes('index') || PERMISSIONS['color'].includes('show')
            },
            {
              title: 'ناقل الحركة',
              translate: 'MENU.TRANSMISSION',
              root: true,
              bullet: 'dot',
              icon: 'flaticon-map',
              page: '/transmissions',
              permissions: PERMISSIONS['transmissionType'].includes('index') || PERMISSIONS['transmissionType'].includes('show')
            },
            {
              title: 'نوع الجر',
              translate: 'MENU.PULLTYPES',
              root: true,
              bullet: 'dot',
              icon: 'flaticon-time-1',
              page: '/pull-types',
              permissions: PERMISSIONS['pullType'].includes('index') || PERMISSIONS['pullType'].includes('show')
            },
            {
              title: 'عجلات القيادة',
              translate: 'MENU.STEERINGWHEELS',
              root: true,
              bullet: 'dot',
              icon: '	flaticon-pie-chart',
              page: '/steering-wheels',
              permissions: PERMISSIONS['steeringWheelType'].includes('index') || PERMISSIONS['steeringWheelType'].includes('show')
            }
          ]
        },
        {
          title: 'السيارات',
          root: true,
          icon: 'fa fa-car',
          toggle: 'click',
          permissions: 
            PERMISSIONS['brand'].includes('index') || PERMISSIONS['brand'].includes('show') ||
            PERMISSIONS['carModel'].includes('index') || PERMISSIONS['carModel'].includes('show') ||
            PERMISSIONS['category'].includes('index') || PERMISSIONS['category'].includes('show') ||
            PERMISSIONS['branch'].includes('index') || PERMISSIONS['branch'].includes('show'),
          submenu: [
            {
              title: 'الماركات',
              translate: 'MENU.BRANDS',
              root: true,
              bullet: 'dot',
              icon: 'flaticon2-tag',
              page: '/brands',
              permissions: PERMISSIONS['brand'].includes('index') || PERMISSIONS['brand'].includes('show')
            },
            {
              title: 'موديلات السيارة',
              translate: 'MENU.CARMODELS',
              root: true,
              bullet: 'dot',
              icon: '	flaticon-pie-chart',
              page: '/car-models',
              permissions: PERMISSIONS['carModel'].includes('index') || PERMISSIONS['carModel'].includes('show')
            },
            {
              title: 'الفئات',
              translate: 'MENU.CATEGORIES',
              root: true,
              bullet: 'dot',
              icon: 'flaticon-squares-4',
              page: '/categories',
              permissions: PERMISSIONS['category'].includes('index') || PERMISSIONS['category'].includes('show')
            },
            // {
            //   title: 'الكاتولجات',
            //   translate: 'MENU.CATALOGUES',
            //   root: true,
            //   bullet: 'dot',
            //   icon: '	flaticon-grid-menu',
            //   page: '/catalogues'
            // },
            {
              title: 'الفروع',
              translate: 'MENU.BRANCHES',
              root: true,
              bullet: 'dot',
              icon: '	flaticon-grid-menu',
              page: '/branches',
              permissions: PERMISSIONS['branch'].includes('index') || PERMISSIONS['branch'].includes('show')
            }
          ]
        },
        {
          title: 'المناطق',
          root: true,
          icon: '	flaticon-placeholder-2',
          toggle: 'click',
          permissions: 
            PERMISSIONS['governorate'].includes('index') || PERMISSIONS['governorate'].includes('show') ||
            PERMISSIONS['city'].includes('index') || PERMISSIONS['city'].includes('show'),
          submenu: [
            {
              title: 'المحافظات',
              translate: 'MENU.GOVERNORATES',
              root: true,
              bullet: 'dot',
              icon: '	flaticon-placeholder',
              page: '/governorates',
              permissions: PERMISSIONS['governorate'].includes('index') || PERMISSIONS['governorate'].includes('show')
            },
            {
              title: 'المدن',
              translate: 'MENU.CITIES',
              root: true,
              bullet: 'dot',
              icon: '	flaticon-placeholder-1',
              page: '/cities',
              permissions: PERMISSIONS['city'].includes('index') || PERMISSIONS['city'].includes('show')
            }
          ]
        },
        {
          title: 'الاعدادات',
          root: true,
          icon: 'flaticon-settings-1',
          toggle: 'click',
          permissions: 
            PERMISSIONS['user'].includes('index') || PERMISSIONS['user'].includes('show') ||
            PERMISSIONS['role'].includes('index') || PERMISSIONS['role'].includes('show') ||
            PERMISSIONS['setting'].includes('index') || PERMISSIONS['setting'].includes('show') ||
            PERMISSIONS['budget'].includes('index') || PERMISSIONS['budget'].includes('show') ||
            PERMISSIONS['filterCriteria'].includes('index') || PERMISSIONS['filterCriteria'].includes('show') ||
            PERMISSIONS['faq'].includes('index') || PERMISSIONS['faq'].includes('show'),
          submenu: [
            {
              title: 'المديرين',
              translate: 'MENU.MANAGERS',
              root: true,
              bullet: 'dot',
              icon: 'flaticon-users',
              page: '/managers',
              permissions: PERMISSIONS['user'].includes('index') || PERMISSIONS['user'].includes('show')
            },
            {
              title: 'الأدوار والأذونات',
              translate: 'MENU.ROLES',
              root: true,
              bullet: 'dot',
              icon: 'fa fa-cogs',
              page: '/roles',
              permissions: PERMISSIONS['role'].includes('index') || PERMISSIONS['role'].includes('show')
            },
            {
              title: 'الاعدادات',
              translate: 'MENU.SETTINGS',
              root: true,
              bullet: 'dot',
              icon: 'flaticon-cogwheel-2',
              page: '/settings',
              permissions: PERMISSIONS['setting'].includes('index') || PERMISSIONS['setting'].includes('show')
            },
            {
              title: 'الميزانيات',
              translate: 'MENU.BUDGETS',
              root: true,
              bullet: 'dot',
              icon: 'flaticon-price-tag',
              page: '/budgets',
              permissions: PERMISSIONS['budget'].includes('index') || PERMISSIONS['budget'].includes('show')
            },
            {
              title: 'قائمة البحث',
              translate: 'MENU.FILTER',
              root: true,
              bullet: 'dot',
              icon: 'flaticon-search',
              page: '/filter-criteria',
              permissions: PERMISSIONS['filterCriteria'].includes('index') || PERMISSIONS['filterCriteria'].includes('show')
            },
            {
              title: 'الاسئله والاجوبة',
              translate: 'MENU.FAQS',
              root: true,
              bullet: 'dot',
              icon: '	flaticon-information',
              page: '/faqs',
              permissions: PERMISSIONS['faq'].includes('index') || PERMISSIONS['faq'].includes('show')
            }
          ]
        },
        {
          title: 'المرفقات',
          root: true,
          icon: 'fa fa-american-sign-language-interpreting',
          toggle: 'click',
          permissions: 
            PERMISSIONS['news'].includes('index') || PERMISSIONS['news'].includes('show') ||
            PERMISSIONS['carAd'].includes('index') || PERMISSIONS['carAd'].includes('show') ||
            PERMISSIONS['slider'].includes('index') || PERMISSIONS['slider'].includes('show') ||
            PERMISSIONS['offer'].includes('index') || PERMISSIONS['offer'].includes('show') ||
            PERMISSIONS['favourite'].includes('index') || PERMISSIONS['favourite'].includes('show') ||
            PERMISSIONS['message'].includes('index') || PERMISSIONS['message'].includes('show') ||
            PERMISSIONS['jobTitle'].includes('index') || PERMISSIONS['jobTitle'].includes('show') ||
            PERMISSIONS['attorneyPerson'].includes('index') || PERMISSIONS['attorneyPerson'].includes('show') ||
            PERMISSIONS['bankAccount'].includes('index') || PERMISSIONS['bankAccount'].includes('show'),
          submenu: [
            {
              title: 'الاخبار',
              translate: 'MENU.NEWS',
              root: true,
              bullet: 'dot',
              icon: 'flaticon2-checking',
              page: '/news',
              permissions: PERMISSIONS['news'].includes('index') || PERMISSIONS['news'].includes('show')
            },
            {
              title: 'اعلانات السيارات',
              translate: 'MENU.ADVERTISING',
              root: true,
              bullet: 'dot',
              icon: 'flaticon-presentation',
              page: '/advertising',
              permissions: PERMISSIONS['carAd'].includes('index') || PERMISSIONS['carAd'].includes('show')
            },
            {
              title: 'سلايدر',
              translate: 'MENU.SLIDERS',
              root: true,
              bullet: 'dot',
              icon: 'flaticon2-image-file',
              page: '/sliders',
              permissions: PERMISSIONS['slider'].includes('index') || PERMISSIONS['slider'].includes('show')
            },
            {
              title: 'العروض',
              translate: 'MENU.OFFERS',
              root: true,
              bullet: 'dot',
              icon: 'flaticon2-gift-1',
              page: '/offers',
              permissions: PERMISSIONS['offer'].includes('index') || PERMISSIONS['offer'].includes('show')
            },
            {
              title: 'المفضلات',
              translate: 'MENU.FAVOURITES',
              root: true,
              bullet: 'dot',
              icon: 'flaticon-black',
              page: '/favourites',
              permissions: PERMISSIONS['favourite'].includes('index') || PERMISSIONS['favourite'].includes('show')
            },
            {
              title: 'الرسائل',
              translate: 'MENU.MESSAGES',
              root: true,
              bullet: 'dot',
              icon: 'flaticon-chat-1',
              page: '/messages',
              permissions: PERMISSIONS['message'].includes('index') || PERMISSIONS['message'].includes('show')
            },
            {
              title: 'المسمي الوظيفي',
              translate: 'MENU.JOBTITLE',
              root: true,
              bullet: 'dot',
              icon: 'flaticon-medal',
              page: '/job-titles',
              permissions: PERMISSIONS['jobTitle'].includes('index') || PERMISSIONS['jobTitle'].includes('show')
            },
            {
              title: 'مندوبين الترخيص',
              translate: 'MENU.ATTORNEY',
              root: true,
              bullet: 'dot',
              icon: 'flaticon-user-ok',
              page: '/attorney',
              permissions: PERMISSIONS['attorneyPerson'].includes('index') || PERMISSIONS['attorneyPerson'].includes('show')
            },
            {
              title: 'الحسابات البنكية',
              translate: 'MENU.BANKACCOUNTS',
              root: true,
              bullet: 'dot',
              icon: 'flaticon-customer',
              page: '/bank-accounts',
              permissions: PERMISSIONS['bankAccount'].includes('index') || PERMISSIONS['bankAccount'].includes('show')
            }
          ]
        }
      ]
    },
  };

  public get configs(): any {
    return this.defaults;
  }
}
