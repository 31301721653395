import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  getData(key: string) {
    return JSON.parse(localStorage.getItem(environment.appName + '-' + key));
  }
  setData(key: string, data: any) {
    localStorage.setItem(environment.appName + '-' + key, JSON.stringify(data));
  }
  removeData(key: string) {
    localStorage.removeItem(environment.appName + '-' + key);
  }
  clearAll() {
    localStorage.clear();
  }
}
